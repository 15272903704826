import React from 'react'
import './HomePage.css'
import girl1 from '../../images/girl-1.png'
import girl2 from '../../images/girl-2.png'
import Button from '../Button/Button'
import Gallery from './Gallery'
import InfoPack from '../AboutPage/InfoPack.pdf'

const HomePage = () => {
  return (
  <div className='home-page'>
    <h1 className='cc-choreography'>CC Choreography</h1>
    <hr className='divider-small-screen' />
    <h2 className='tag-line'>Join our dance family today</h2>
    <div className='img-wrapper-left'>
    <img src={girl1} alt='CC Choreography'/>
    </div>
    <div className='img-wrapper-right'>
    <img src={girl2} alt='CC Choreography'/>
    </div>

    <hr className='divider-large-screen' />

    <div className='home-page-buttons'>
      <Button href={InfoPack} download text='Download 2024 Info Pack' />
        {/* <a href={InfoPack} download className='home-page-button'>
          <div>Download 2022 Info Pack</div>
        </a> */}

      <Button href='/contact' text='Contact us for a free trial' />

        {/* <a href='/contact' className='home-page-button'>
          <div>
            Contact us for a FREE TRIAL
          </div>
        </a> */}
    </div>

    <div className='key-statements'>
      <div>Jazz</div>
      <div>Hip Hop</div>
      <div>Contemporary</div>
      <div>Musical Theatre</div>
      <div>Acro Dance</div>
      <div>Ballet</div>
      <div>Tap</div>
      <div>Troupe</div>
      <div>Tiny Tots</div>
      <div>Technique</div>
      <div>Private Tuition</div>
    </div>

    <Gallery />
  </div>
  )
}

export default HomePage