import React, { useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import menu from './NavConfig'

const MobileNavDrawer = () => {
  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setShowDrawer(open)
  }

  const list =
    <div
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className='mobile-nav-bar'>
        {menu.map(menuItem =>
          <NavLink 
            key={menuItem} 
            className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}
            end 
            to={menuItem === 'Home' ? '/' : `/${menuItem.toLowerCase()}`}>
            {menuItem}
          </NavLink>
        )}
      </div>
    </div>

  return (
    <div>
      <div className='mobile-nav-icon' onClick={toggleDrawer(true)}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <SwipeableDrawer
        anchor='right'
        open={showDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list}
      </SwipeableDrawer>
    </div>
  )
}

export default MobileNavDrawer