import React from 'react'
import './Button.css'

const Button = props => {
  return (
    <a href={props.href} download={props.download} className='button'>
      <div>{props.text}</div>
    </a>
  )
}

export default Button