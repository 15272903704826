export const fees1 = [
  {class: '1 x 30min Class', price: '$10'},
  {class: '1 x 45min Class', price: '$15'},
  {class: '1 x 60min Class', price: '$19'},
]

export const fees2 = [
  {class: 'Solo Private', price: '$35 / 30 minutes'},
  {class: 'Duo/Trio Private', price: '$22 / 30 minutes p.p.'},
  {class: 'Annual Registration (not required for Tiny Tots or Lil Groovers)', price: '$60'},
]