export const timetable = [
  // Row 1
  {
    Monday: {
        Class: 'Acro / Cheer',
        Time: '3.30 - 4.30pm'
      },
    Tuesday: {
      Class: 'Junior Jazz & Contemp.',
      Time: '3.30 - 4.30pm'
    },
    Wednesday: {
      Class: 'Tiny Tots',
      Time: '9.30 - 10am'
    },
    Thursday: {
      Class: 'Troupe 12&U',
      Time: '3.30 - 4:30pm'
    },
    Friday: {
      Class: 'Tap 2',
      Time: '3:30 - 4.15pm'
    },
  },
  // Row 2
  {
    Monday: {
      Class: 'Hip Hop',
      Time: '4.30 - 5:30pm'
    },
    Tuesday: {
      Class: 'Musical Theatre',
      Time: '4.30 - 5.15pm'
    },
    Wednesday: {
      Class: 'Lil Groovers',
      Time: '10 - 10.45am'
    },
    Thursday: {
      Class: 'Troupe 13+',
      Time: '4:30 - 5.30pm'
    },
    Friday: {
      Class: 'Tap 1',
      Time: '4.15 - 5pm'
    },
  },
  // Row 3
  {
    Monday: {
      Class: 'Private Lessons',
      Time: 'From 5:30pm'
    },
    Tuesday: {
      Class: 'Inter Jazz',
      Time: '5.15 - 6pm'
    },
    Wednesday: {
      Class: 'Pre-Int Jazz',
      Time: '3.30 - 4.15pm'
    },
    Thursday: {
      Class: 'Senior Jazz/Contemp.',
      Time: '5.30 - 6.30pm'
    },
    Friday: {
      Class: 'Tap 3',
      Time: '5 - 5.45pm'
    },
  },
  // Row 4
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: 'Inter Contemp.',
      Time: '6 - 6.45pm'
    },
    Wednesday: {
      Class: 'Pre-Int Contemp.',
      Time: '4.15 - 5pm'
    },
    Thursday: {
      Class: 'Private Lessons',
      Time: 'From 6.30pm'
    },
    Friday: {
      Class: 'Ballet',
      Time: '5.45 - 6.30pm'
    },
  },
  // Row 5
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: 'Adults Open Class',
      Time: '7 - 8pm'
    },
    Wednesday: {
      Class: 'Pre-Senior Jazz',
      Time: '5 - 5.45pm'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    }
  },
  // Row 6
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: '',
      Time: ''
    },
    Wednesday: {
      Class: 'Pre-Senior Contemp.',
      Time: '5.45 - 6.30pm'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    }
  },
  // Row 7
  {
    Monday: {
      Class: '',
      Time: ''
    },
    Tuesday: {
      Class: '',
      Time: ''
    },
    Wednesday: {
      Class: 'Private Lessons',
      Time: 'From 6.30pm'
    },
    Thursday: {
      Class: '',
      Time: ''
    },
    Friday: {
      Class: '',
      Time: ''
    }
  }
]

export const ageGroups = [
  'Tiny Tots: 18mth-3yrs',
  'Lil Groovers: 3-5yrs',
  'Junior: K-Y1',
  'Pre-Intermediates: Y2-Y3',
  'Intermediates: Y4-Y5',
  'Pre-Senior: Y6-Y7',
  'Senior: Y8+',
  'Tap & Ballet are based on age & ability combined',
  'Hip Hop, Musical Theatre & Acro are open-age classes'
]