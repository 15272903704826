import chelsea from '../../images/chelsea.jpg'
import max from '../../images/max.jpeg'
import bella from '../../images/bella.png'
import amy from '../../images/amy.JPG'

const teachers = [
  {
    name: 'Chelsea Cole',
    position: 'Director and Principal Dance Teacher & Choreographer',
    image: chelsea,
    about: 
    'Miss Chelsea has over 20 years dance experience and 10 years teaching experience. '
    + 'She teaches students from 2.5 years young, up to adults! Chelsea opened her Black Swan Dance Studio in 2015 '
    + '(re-named to CC Choreography in 2020). Her energy and passion for her work is evident to everyone who watches her teach and perform.'
    + '<br><br>Chelsea has experience teaching Jazz, Tap, Contemporary, Lyrical, Hip Hop, Acrobatics, Tiny Tots and Adults Dance Fitness. '
    + 'She is always pursuing new dance knowledge and styles and has also learnt Brazilian Zouk Partner Dancing, Salsa and Bachata styles.'
    + '<br><br>Over the years, she has choreographed and mentored students to winning countless solo, duo and troupe performance pieces '
    + 'at local eisteddfods around Newcastle.'
  },
  {
    name: 'Miss Bella',
    position: 'Dance Teacher & Choreographer',
    image: bella,
    about: 'Miss Bella joined our Dance Family in 2020! She has an bubbly and friendly personality, '
    + 'which the students have grown to adore very quickly!'
    + '<br><br>Bella started dancing at Baby Ballerinas and trained formally at Newcastle Ballet Theatre. '
    + 'She has completed her Certificate III and IV in Dance at Newcastle Ballet Theatre '
    + 'and she is also a qualified Pilates Instructor!'
    + '<br><br>Bella was a part of Newcastle\'s Award winning youth contemporary dance company '
    + '- The Flipside Project and toured around Australia with them. '
    + 'She was a part of the group that received \'Most outstanding youth dance ensemble\' '
    + 'at the Australian Dance Awards.'
    + '<br><br>Bella has collaborated with some of Australia\'s most sought after choreographers; '
    + 'Kristina Chan, Craig Bary, Omer Backley-Astrchan, Cadi McCarthy, Idan Cohen, Israel Aloni and Amy Hollingsworth.'
    + '<br><br>We are so happy to have Bella on board our teaching staff at CC Choreography.'
  },
  {
    name: 'Mr Max',
    position: 'Dance Teacher & Choreographer',
    image: max,
    about: 'Max is an outgoing and energetic instructor across multiple dance styles! '
    + 'His love for dance and teaching can easily be seen in any of his classes. '
    + 'He has many years teaching experience at other studios since January 2016! '
    + 'He has taught Hip Hop, Jazz, Break Dance, Acro Dance and Tap.'
    + '<br><br>Max received official accreditation and training for Acro Dance teaching '
    + 'through Sydney Acrobatics School and as of this year (2020), he will be qualified to teach LGTDA Tap exams. '
    + 'He has danced overseas in Disneyland Anaheim, Disneyworld Florida and on board the '
    + 'Royal Caribbean Freedom of the Seas Cruise Ship.'
  },
  {
    name: 'Miss Amy',
    position: 'Dance Teacher & Choreographer',
    image: amy,
    about: 'Miss Amy is a performer, dance teacher & choreographer! '
    + 'She trained for two years full time at Ettingshausens Pro and Lee Academy '
    + 'where she completed her Cert IV and Diploma of Dance. '
    + 'She has also been teaching dance for multiple years!'
    + '<br><br>Amy has experience in Jazz, Contemporary, Hip Hop, Ballet, Musical Theatre, Technique & '
    + 'Beginner Aerial Experience! She is certainly an all-rounder and an asset to our studio!'
  }
]

export default teachers