import React from 'react'
// import ButtRAon from '../Button/Button'
import Icons from '../Icons/Icons'
import PageWrapper from '../PageWrapper/PageWrapper'
import aboutPageImage from '../../images/about-page.jpg'
import aboutPageImage2 from '../../images/about-page-2.JPG'
import tinyTotsImage from '../../images/about-tiny-tots.JPG'
import InfoPack from './InfoPack.pdf'
// import danceStyles1 from '../../images/dance-styles-1.PNG'
// import danceStyles2 from '../../images/dance-styles-2.PNG'
// import facilities1 from '../../images/facilities-1.PNG'
// import facilities2 from '../../images/facilities-2.PNG'
import LogoWrappedImage from '../LogoWrappedImage/LogoWrappedImage'
import './AboutPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const AboutPage = () => {
  return (
    <PageWrapper title='About'>
      <div className='about-grid'>

        <LogoWrappedImage noGrow autoHeight altText='About' image={aboutPageImage} />


        <div className='about-text'>
          CC Choreography welcomes everyone from beginners to experienced dancers, of all ages!
          <br/><br />
          Our specialised teachers support, encourage, nurture and promote a passion for dance in a safe & welcoming environment!
          
          <div className='social-and-download-large-screen'>
            <br/>
            Find us on Social Media or Email us for any enquiries.
            <div className='about-icons'>
              <Icons instagram facebook email />
            </div>

            {/* <Button href={InfoPack} download text='Download 2022 Info Pack' /> */}
            <a href={InfoPack} download className='download-info-pack'>
              <div>Download 2024 Information Pack</div>
              <FontAwesomeIcon icon={faDownload} />
            </a>
          </div>
        </div>
      </div>

      <div className='social-and-download-small-screen'>
        Find us on Social Media or Email us for any enquiries.
        <div className='about-icons'>
          <Icons instagram facebook email />
        </div>

        <a href={InfoPack} download className='download-info-pack'>
          <div>Download 2024 Information Pack</div>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>

    <h2 className='about-sub-heading'>We Offer:</h2>
    <div className='about-grid-2'>    
      <img className='about-image' src={aboutPageImage2} alt='Dancing Girl' />
      <ul className='we-offer'>
        <li>jazz</li>
        <li>hip hop</li>
        <li>contemporary</li>
        <li>musical theatre</li>
        <li>acro-dance</li>
        <li>ballet</li>
        <li>troupe</li>
        <li>tiny tots</li>
        <li>tap</li>
        <li>privates</li>
      </ul>

      
    </div>

    <h2 className='about-sub-heading'>Little Ones:</h2>
    <div className='about-grid'>
      <img className='about-image tiny-tots-image' src={tinyTotsImage} alt='Tiny Tots' />

      <div className='about-text'>
        We have a beautiful Program dedicated to our littlest dancers!
        we aim to share our love of dance in a fun, caring & engaging class! filled with music & props - they experience a taste of ballet & jazz!
        <br/><br/>
        Tiny Tots: 18 months - 3 years 
        <br/><br/>
        Lil Groovers: 3 years - 5 years 
        <br/><br/>
        Parents are welcome to stay!!
      </div>
    </div>
    
    {/* <div className='about-grid about-grid-small'>
      <div>
        <img src={facilities1} alt='CC Choreography' />
      </div>

      <div>
        <img src={facilities2} alt='CC Choreography' />
      </div>
    </div> */}
     
     {/* <h2 className='about-sub-heading'>Dance Styles</h2>
     <hr />

      <div className='about-grid about-grid-small'>
        <div>
          <img src={danceStyles1} alt='CC Choreography' />
        </div>

        <div>
          <img src={danceStyles2} alt='CC Choreography' />
        </div>
      </div> */}
    </PageWrapper>
  )
}

export default AboutPage