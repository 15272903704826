import React from 'react'
import { useParams } from 'react-router-dom'
import PageWrapper from '../../PageWrapper/PageWrapper'
import ImageGallery from 'react-image-gallery'
import images from '../GalleryConfig'
import './GallerySpecific.css'

const GallerySpecific = () => {
  const params = useParams()
  
  return (
    <PageWrapper 
      smallTitle 
      title={`${params.category === 'danceevents' ? 'Dance Events' : params.category} Photos`}
    >
      {params.category !== '2020' ? null :
        <div className='concert-link'>
          See each item from our 2020 concert on our <a target='__blank' href='https://www.youtube.com/channel/UCwJnhZ1LfN9RNE9kyM0a1hQ/videos'>YouTube channel</a>
        </div>
      }
      <ImageGallery showBullets items={images[params.category]} />
    </PageWrapper>
  )
}

export default GallerySpecific