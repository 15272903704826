import React from 'react'
import ccsymbol from '../../images/symbol-grey.png'
import './LogoWrappedImage.css'

const LogoWrappedImage = props => {
  return (
    <div 
      className={`logo-wrapping-image${props.noGrow ? '' : ' grow'}${!props.autoHeight ? '' : ' auto-height'}`} 
    >
      <img src={ccsymbol} alt='CC Choreography' />
      <img src={props.image} alt={`${props.altText} CC Choreography`} />
    </div>
  )
}

export default LogoWrappedImage