const testimonials = [
  {
    name: 'K. Monkhouse', 
    stars: 5,
    year: 2022,
    review: 'The team here has been fantastic, welcoming my daughter with open arms. '
    + 'They make dance fun, flexible and engaging. Dance as it should be ... '
    + 'Enjoyable for the mind, body and spirit!!!'
  },
  {
    name: 'D. King', 
    stars: 5,
    year: 2022,
    review: 'Such a wonderful dance studio. My daughter has thrived in this studio '
    + 'where the culture is supportive, friendly and nurturing. Lots of opportunities '
    + 'for all abilities and ages.'
  },
  {
    name: 'A. Girgis', 
    stars: 5,
    year: 2021,
    review: 'I can’t recommend CC Choreography, Miss Chelsea and her team of teachers '
    + 'highly enough! The dancers always look fabulous and are obviously thoroughly enjoying '
    + 'themselves and the concerts are a must-go-to every year!'
  },
  {
    name: 'D. King', 
    stars: 5,
    year: 2021,
    review: 'I can’t recommend this studio enough. Chelsea and her team have created '
    + 'such a supportive and safe environment. It has been wonderful to see my daughter grow with '
    + 'confidence over the year and her love of dance continues to grow every year!'
  },
  {
    name: 'J. Davenport', 
    stars: 5,
    year: 2021,
    review: 'CC Choreography has lovely teachers who care about all of their students. '
    + 'My kids have been dancing there for many years & always look forward to their classes. '
    + 'The end of year concert costumes are affordable and suit the age groups. '
    + 'I’d recommend this studio to both first time dancers and dancers who have trained before.'
  },
  {
    name: 'C. Armstrong',
    stars: 5,
    year: '2020',
    review: 'Chelsea\'s studio is the most welcoming and supportive environment for young people to learn dance. '
    + 'Professionally-run, it is a joy to have both my children dance there together.'
  },
  { 
    name: 'J. Dave',
    stars: 5,
    year: '2020',
    review: 'Chelsea is enthusiastic and encouraging. Every week she inspires kids to work hard and develop their skills. '
    + 'We are going into our 6th year with her and the improvements across the whole student cohort are a pleasure to watch.'
  },
  { 
    name: 'J. Goodsir',
    stars: 5,
    year: '2020',
    review: 'Our daughter has grown so much as a dancer at CC Choreography. '
    + 'She\'s become so strong and her technique has improved to no end. '
    + 'Miss Chelsea is also an incredible role model to the kids, who look up to her immensely.'
  },
  { 
    name: 'D. King',
    stars: 5,
    year: '2020',
    review: 'A great dance studio and I would highly recommend. '
    + 'Chelsea has created a great supportive environment for her studio. '
    + 'It has been wonderful to see my daughters grow and develop confidence in themselves and a love of dance.'
  },
  { 
    name: 'S. Velich',
    stars: 5,
    year: '2020',
    review: 'Chelsea is an excellent dance teacher. Chelsea is highly competent and loves dance and teaching children. '
    + 'I would highly recommend Chelsea & CC Choreography to anyone looking for an awesome environment for their child to learn the art of dance.'
  },
  { 
    name: 'S.B. Kearsey',
    stars: 5,
    year: '2019',
    review: 'My daughter has danced with Black Swan for almost 3 years now! Chelsea and her team are awesome. '
    + 'I love this dance company because it\'s about dancing and fun and not about expensive costumes and making kids look like adults with heavy make up! '
    + 'I would recommend Black Swan to anyone who is looking for a place for their kids to learn dance in a fun, friendly and no-pressure environment!'
  },
  { 
    name: 'A. Cook',
    stars: 5,
    year: '2019',
    review: 'My little girl LOVES Miss Chelsea and loves dance classes each week. '
    + 'Black Swan Dance is a fun, happy, positive Dance School and we love being a part of the Dance Family! '
    + 'I highly recommend for anyone looking for a relaxed, professional and caring dance centre.'
  }
]

export default testimonials