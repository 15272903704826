import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import RootLayout from './Components/RootLayout/RootLayout'
import AboutPage from './Components/AboutPage/AboutPage'
import ContactPage from './Components/ContactPage/ContactPage'
import FeesPage from './Components/FeesPage/FeesPage'
import GalleryHome from './Components/GalleryPage/GalleryHome/GalleryHome'
import GallerySpecific from './Components/GalleryPage/GallerySpecific/GallerySpecific'
import HomePage from './Components/HomePage/HomePage'
import TeachersPage from './Components/TeachersPage/TeachersPage'
import TestimonialsPage from './Components/TestimonialsPage/TestimonialsPage'
import TimetablePage from './Components/TimetablePage/TimetablePage'
import './App.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/about', element: <AboutPage /> },
      { path: '/teachers', element: <TeachersPage /> },
      { path: '/timetable', element: <TimetablePage /> },
      { path: '/fees', element: <FeesPage /> },
      { path: '/testimonials', element: <TestimonialsPage /> },
      { path: '/gallery', element: <GalleryHome /> },
      { path: '/gallery/:category', element: <GallerySpecific /> },
      { path: '/contact', element: <ContactPage /> },
    ]
  }
])

const App = () => {
  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  )
}

export default App
