import React from 'react'

const FeesTable = props => {
  return (
    <table className='fees-table'>
      <thead>
        <tr>
          <th>Classes / Week</th>
          <th>Price (includes GST)</th>
        </tr>
      </thead>
      <tbody>
        {props.tableContent.map(fee =>
          <tr key={fee.class}>
            <td dangerouslySetInnerHTML={{__html: fee.class}} />
            <td dangerouslySetInnerHTML={{__html: fee.price}} />
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default FeesTable