import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageWrapper from '../../PageWrapper/PageWrapper'
import images from '../GalleryConfig'
import './GalleryHome.css'

const GalleryHome = () => {
  const location = useLocation()

  const galleryCategories = ['2023', '2022', '2021', '2020', '2019', '2018', 
    '2017', '2016', '2015', 'Dance Events']

  return (
    <PageWrapper title='Gallery'>
      <div className='gallery-grid'>
        {galleryCategories.map(category =>
          <Link 
            key={category} 
            to={`${location.pathname}/${category.replace(/ /g, '').toLowerCase()}`} 
          >
            <img 
              className='cover-photo' 
              src={images[category.replace(/ /g, '').toLowerCase()][0].original} 
              alt={`${category} Gallery`} 
            />

            <div className='cover-photo-title'>{category}</div>
          </Link>
        )}
      </div>
    </PageWrapper>
  )
}

export default GalleryHome