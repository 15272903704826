import React from 'react'
import ImageGallery from 'react-image-gallery'
import danceStyles1 from '../../images/dance-styles-1.PNG'
import danceStyles2 from '../../images/dance-styles-2.PNG'

const Gallery = () => {
  const images = [
    {original: danceStyles1, thumbnail: danceStyles1},
    {original: danceStyles2, thumbnail: danceStyles2}
  ]

  return (
    <div className='home-page-gallery'>
      <ImageGallery 
        items={images} showNav={false} showThumbnails={false}
        showFullscreenButton={false} showPlayButton={false} autoPlay 
      />
    </div>
  )
}

export default Gallery