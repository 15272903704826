import oneDance from '../../images/DanceEvents/IMG-1.JPG'
import oneDanceSmall from '../../images/DanceEvents/IMG-1-150.jpg'
import twoDance from '../../images/DanceEvents/IMG-2.JPG'
import twoDanceSmall from '../../images/DanceEvents/IMG-2-150.jpg'
import threeDance from '../../images/DanceEvents/IMG-3.JPG'
import threeDanceSmall from '../../images/DanceEvents/IMG-3-150.jpg'
import fourDance from '../../images/DanceEvents/IMG-4.JPG'
import fourDanceSmall from '../../images/DanceEvents/IMG-4-150.jpg'
import fiveDance from '../../images/DanceEvents/IMG-5.JPG'
import fiveDanceSmall from '../../images/DanceEvents/IMG-5-150.jpg'
import sixDance from '../../images/DanceEvents/IMG-6.JPG'
import sixDanceSmall from '../../images/DanceEvents/IMG-6-150.jpg'
import sevenDance from '../../images/DanceEvents/IMG-7.JPG'
import sevenDanceSmall from '../../images/DanceEvents/IMG-7-150.jpg'
import eightDance from '../../images/DanceEvents/IMG-8.JPG'
import eightDanceSmall from '../../images/DanceEvents/IMG-8-150.jpg'
import nineDance from '../../images/DanceEvents/IMG-9.JPG'
import nineDanceSmall from '../../images/DanceEvents/IMG-9-150.jpg'

import one2023 from '../../images/2023/IMG-1.JPG'
import one2023Small from '../../images/2023/IMG-1-150.jpg'
import two2023 from '../../images/2023/IMG-2.JPG'
import two2023Small from '../../images/2023/IMG-2-150.jpg'
import three2023 from '../../images/2023/IMG-3.JPG'
import three2023Small from '../../images/2023/IMG-3-150.jpg'
import four2023 from '../../images/2023/IMG-4.JPG'
import four2023Small from '../../images/2023/IMG-4-150.jpg'
import five2023 from '../../images/2023/IMG-5.JPG'
import five2023Small from '../../images/2023/IMG-5-150.jpg'
import six2023 from '../../images/2023/IMG-6.JPG'
import six2023Small from '../../images/2023/IMG-6-150.jpg'
import seven2023 from '../../images/2023/IMG-7.JPG'
import seven2023Small from '../../images/2023/IMG-7-150.jpg'
import eight2023 from '../../images/2023/IMG-8.JPG'
import eight2023Small from '../../images/2023/IMG-8-150.jpg'
import nine2023 from '../../images/2023/IMG-9.JPG'
import nine2023Small from '../../images/2023/IMG-9-150.jpg'
import ten2023 from '../../images/2023/IMG-10.JPG'
import ten2023Small from '../../images/2023/IMG-10-150.jpg'
import eleven2023 from '../../images/2023/IMG-11.JPG'
import eleven2023Small from '../../images/2023/IMG-11-150.jpg'
import twelve2023 from '../../images/2023/IMG-12.JPG'
import twelve2023Small from '../../images/2023/IMG-12-150.jpg'
import thirteen2023 from '../../images/2023/IMG-13.JPG'
import thirteen2023Small from '../../images/2023/IMG-13-150.jpg'
import fourteen2023 from '../../images/2023/IMG-14.JPG'
import fourteen2023Small from '../../images/2023/IMG-14-150.jpg'
import fifteen2023 from '../../images/2023/IMG-15.JPG'
import fifteen2023Small from '../../images/2023/IMG-15-150.jpg'
import sixteen2023 from '../../images/2023/IMG-16.JPG'
import sixteen2023Small from '../../images/2023/IMG-16-150.jpg'
import seventeen2023 from '../../images/2023/IMG-17.JPG'
import seventeen2023Small from '../../images/2023/IMG-17-150.jpg'
import eighteen2023 from '../../images/2023/IMG-18.JPG'
import eighteen2023Small from '../../images/2023/IMG-18-150.jpg'
import nineteen2023 from '../../images/2023/IMG-19.JPG'
import nineteen2023Small from '../../images/2023/IMG-19-150.jpg'
import twenty2023 from '../../images/2023/IMG-20.JPG'
import twenty2023Small from '../../images/2023/IMG-20-150.jpg'
import twentyone2023 from '../../images/2023/IMG-21.JPG'
import twentyone2023Small from '../../images/2023/IMG-21-150.jpg'
import twentytwo2023 from '../../images/2023/IMG-22.JPG'
import twentytwo2023Small from '../../images/2023/IMG-22-150.jpg'
import twentythree2023 from '../../images/2023/IMG-23.JPG'
import twentythree2023Small from '../../images/2023/IMG-23-150.jpg'
import twentyfour2023 from '../../images/2023/IMG-24.JPG'
import twentyfour2023Small from '../../images/2023/IMG-24-150.jpg'
import twentyfive2023 from '../../images/2023/IMG-25.JPG'
import twentyfive2023Small from '../../images/2023/IMG-25-150.jpg'
import twentysix2023 from '../../images/2023/IMG-26.JPG'
import twentysix2023Small from '../../images/2023/IMG-26-150.jpg'
import twentyseven2023 from '../../images/2023/IMG-27.JPG'
import twentyseven2023Small from '../../images/2023/IMG-27-150.jpg'
import twentyeight2023 from '../../images/2023/IMG-28.JPG'
import twentyeight2023Small from '../../images/2023/IMG-28-150.jpg'
import twentynine2023 from '../../images/2023/IMG-29.JPG'
import twentynine2023Small from '../../images/2023/IMG-29-150.jpg'
import thirty2023 from '../../images/2023/IMG-30.JPG'
import thirty2023Small from '../../images/2023/IMG-30-150.jpg'
import thirtyone2023 from '../../images/2023/IMG-31.JPG'
import thirtyone2023Small from '../../images/2023/IMG-31-150.jpg'
import thirtytwo2023 from '../../images/2023/IMG-32.JPG'
import thirtytwo2023Small from '../../images/2023/IMG-32-150.jpg'
import thirtythree2023 from '../../images/2023/IMG-33.JPG'
import thirtythree2023Small from '../../images/2023/IMG-33-150.jpg'
import thirtyfour2023 from '../../images/2023/IMG-34.JPG'
import thirtyfour2023Small from '../../images/2023/IMG-34-150.jpg'
import thirtyfive2023 from '../../images/2023/IMG-35.JPG'
import thirtyfive2023Small from '../../images/2023/IMG-35-150.jpg'
import thirtysix2023 from '../../images/2023/IMG-36.JPG'
import thirtysix2023Small from '../../images/2023/IMG-36-150.jpg'
import thirtyseven2023 from '../../images/2023/IMG-37.JPG'
import thirtyseven2023Small from '../../images/2023/IMG-37-150.jpg'

import one2022 from '../../images/2022/IMG-1.jpg'
import one2022Small from '../../images/2022/IMG-1-150.jpg'
import two2022 from '../../images/2022/IMG-2.jpg'
import two2022Small from '../../images/2022/IMG-2-150.jpg'
import three2022 from '../../images/2022/IMG-3.jpg'
import three2022Small from '../../images/2022/IMG-3-150.jpg'
import four2022 from '../../images/2022/IMG-4.jpg'
import four2022Small from '../../images/2022/IMG-4-150.jpg'
import five2022 from '../../images/2022/IMG-5.jpg'
import five2022Small from '../../images/2022/IMG-5-150.jpg'
import six2022 from '../../images/2022/IMG-6.jpg'
import six2022Small from '../../images/2022/IMG-6-150.jpg'
import seven2022 from '../../images/2022/IMG-7.jpg'
import seven2022Small from '../../images/2022/IMG-7-150.jpg'
import eight2022 from '../../images/2022/IMG-8.jpg'
import eight2022Small from '../../images/2022/IMG-8-150.jpg'
import nine2022 from '../../images/2022/IMG-9.jpg'
import nine2022Small from '../../images/2022/IMG-9-150.jpg'
import ten2022 from '../../images/2022/IMG-10.jpg'
import ten2022Small from '../../images/2022/IMG-10-150.jpg'
import eleven2022 from '../../images/2022/IMG-11.jpg'
import eleven2022Small from '../../images/2022/IMG-11-150.jpg'
import twelve2022 from '../../images/2022/IMG-12.jpg'
import twelve2022Small from '../../images/2022/IMG-12-150.jpg'
import thirteen2022 from '../../images/2022/IMG-13.jpg'
import thirteen2022Small from '../../images/2022/IMG-13-150.jpg'
import fourteen2022 from '../../images/2022/IMG-14.jpg'
import fourteen2022Small from '../../images/2022/IMG-14-150.jpg'
import fifteen2022 from '../../images/2022/IMG-15.jpg'
import fifteen2022Small from '../../images/2022/IMG-15-150.jpg'
import sixteen2022 from '../../images/2022/IMG-16.jpg'
import sixteen2022Small from '../../images/2022/IMG-16-150.jpg'
import seventeen2022 from '../../images/2022/IMG-17.jpg'
import seventeen2022Small from '../../images/2022/IMG-17-150.jpg'
import eighteen2022 from '../../images/2022/IMG-18.jpg'
import eighteen2022Small from '../../images/2022/IMG-18-150.jpg'
import nineteen2022 from '../../images/2022/IMG-19.jpg'
import nineteen2022Small from '../../images/2022/IMG-19-150.jpg'
import twenty2022 from '../../images/2022/IMG-20.jpg'
import twenty2022Small from '../../images/2022/IMG-20-150.jpg'
import twentyone2022 from '../../images/2022/IMG-21.jpg'
import twentyone2022Small from '../../images/2022/IMG-21-150.jpg'
import twentytwo2022 from '../../images/2022/IMG-22.jpg'
import twentytwo2022Small from '../../images/2022/IMG-22-150.jpg'
import twentythree2022 from '../../images/2022/IMG-23.jpg'
import twentythree2022Small from '../../images/2022/IMG-23-150.jpg'
import twentyfour2022 from '../../images/2022/IMG-24.jpg'
import twentyfour2022Small from '../../images/2022/IMG-24-150.jpg'


import one2021 from '../../images/2021/IMG-1.jpeg'
import one2021Small from '../../images/2021/IMG-1-150.jpg'
import two2021 from '../../images/2021/IMG-2.jpeg'
import two2021Small from '../../images/2021/IMG-2-150.jpg'
import three2021 from '../../images/2021/IMG-3.jpeg'
import three2021Small from '../../images/2021/IMG-3-150.jpg'
import four2021 from '../../images/2021/IMG-4.jpeg'
import four2021Small from '../../images/2021/IMG-4-150.jpg'
import five2021 from '../../images/2021/IMG-5.jpeg'
import five2021Small from '../../images/2021/IMG-5-150.jpg'
import six2021 from '../../images/2021/IMG-6.jpeg'
import six2021Small from '../../images/2021/IMG-6-150.jpg'
import seven2021 from '../../images/2021/IMG-7.jpeg'
import seven2021Small from '../../images/2021/IMG-7-150.jpg'
import eight2021 from '../../images/2021/IMG-8.jpeg'
import eight2021Small from '../../images/2021/IMG-8-150.jpg'
import nine2021 from '../../images/2021/IMG-9.jpeg'
import nine2021Small from '../../images/2021/IMG-9-150.jpg'
import ten2021 from '../../images/2021/IMG-10.jpeg'
import ten2021Small from '../../images/2021/IMG-10-150.jpg'
import eleven2021 from '../../images/2021/IMG-11.jpeg'
import eleven2021Small from '../../images/2021/IMG-11-150.jpg'
import twelve2021 from '../../images/2021/IMG-12.jpeg'
import twelve2021Small from '../../images/2021/IMG-12-150.jpg'
import thirteen2021 from '../../images/2021/IMG-13.jpeg'
import thirteen2021Small from '../../images/2021/IMG-13-150.jpg'
import fourteen2021 from '../../images/2021/IMG-14.jpeg'
import fourteen2021Small from '../../images/2021/IMG-14-150.jpg'
import fifteen2021 from '../../images/2021/IMG-15.jpeg'
import fifteen2021Small from '../../images/2021/IMG-15-150.jpg'
import sixteen2021 from '../../images/2021/IMG-16.jpeg'
import sixteen2021Small from '../../images/2021/IMG-16-150.jpg'

import one2020 from '../../images/2020/IMG-1.jpeg'
import one2020Small from '../../images/2020/IMG-1-150.jpg'
import two2020 from '../../images/2020/IMG-2.jpeg'
import two2020Small from '../../images/2020/IMG-2-150.jpeg'
import three2020 from '../../images/2020/IMG-3.jpeg'
import three2020Small from '../../images/2020/IMG-3-150.jpeg'
import four2020 from '../../images/2020/IMG-4.jpeg'
import four2020Small from '../../images/2020/IMG-4-150.jpg'
import five2020 from '../../images/2020/IMG-5.jpeg'
import five2020Small from '../../images/2020/IMG-5-150.jpeg'
import six2020 from '../../images/2020/IMG-6.jpeg'
import six2020Small from '../../images/2020/IMG-6-150.jpeg'
import seven2020 from '../../images/2020/IMG-7.jpeg'
import seven2020Small from '../../images/2020/IMG-7-150.jpeg'
import eight2020 from '../../images/2020/IMG-8.jpeg'
import eight2020Small from '../../images/2020/IMG-8-150.jpg'
import nine2020 from '../../images/2020/IMG-9.jpeg'
import nine2020Small from '../../images/2020/IMG-9-150.jpeg'
import ten2020 from '../../images/2020/IMG-10.jpeg'
import ten2020Small from '../../images/2020/IMG-10-150.jpeg'
import eleven2020 from '../../images/2020/IMG-11.jpeg'
import eleven2020Small from '../../images/2020/IMG-11-150.jpeg'
import twelve2020 from '../../images/2020/IMG-12.jpeg'
import twelve2020Small from '../../images/2020/IMG-12-150.jpeg'
import thirteen2020 from '../../images/2020/IMG-13.jpeg'
import thirteen2020Small from '../../images/2020/IMG-13-150.jpeg'
import fourteen2020 from '../../images/2020/IMG-14.jpeg'
import fourteen2020Small from '../../images/2020/IMG-14-150.jpeg'
import fifteen2020 from '../../images/2020/IMG-15.jpeg'
import fifteen2020Small from '../../images/2020/IMG-15-150.jpeg'
import sixteen2020 from '../../images/2020/IMG-16.jpeg'
import sixteen2020Small from '../../images/2020/IMG-16-150.jpg'
import seventeen2020 from '../../images/2020/IMG-17.jpeg'
import seventeen2020Small from '../../images/2020/IMG-17-150.jpeg'
import eighteen2020 from '../../images/2020/IMG-18.jpeg'
import eighteen2020Small from '../../images/2020/IMG-18-150.jpeg'
import nineteen2020 from '../../images/2020/IMG-19.jpeg'
import nineteen2020Small from '../../images/2020/IMG-19-150.jpeg'
import twenty2020 from '../../images/2020/IMG-20.jpeg'
import twenty2020Small from '../../images/2020/IMG-20-150.jpeg'

import one2019 from '../../images/2019/IMG-1.JPG'
import one2019Small from '../../images/2019/IMG-1-150.jpg'
import two2019 from '../../images/2019/IMG-2.JPG'
import two2019Small from '../../images/2019/IMG-2-150.jpg'
import three2019 from '../../images/2019/IMG-3.JPG'
import three2019Small from '../../images/2019/IMG-3-150.jpg'
import four2019 from '../../images/2019/IMG-4.JPG'
import four2019Small from '../../images/2019/IMG-4-150.jpg'
import five2019 from '../../images/2019/IMG-5.JPG'
import five2019Small from '../../images/2019/IMG-5-150.jpg'
import six2019 from '../../images/2019/IMG-6.JPG'
import six2019Small from '../../images/2019/IMG-6-150.jpg'
import seven2019 from '../../images/2019/IMG-7.JPG'
import seven2019Small from '../../images/2019/IMG-7-150.jpg'
import eight2019 from '../../images/2019/IMG-8.JPG'
import eight2019Small from '../../images/2019/IMG-8-150.jpg'
import nine2019 from '../../images/2019/IMG-9.JPG'
import nine2019Small from '../../images/2019/IMG-9-150.jpg'
import ten2019 from '../../images/2019/IMG-10.JPG'
import ten2019Small from '../../images/2019/IMG-10-150.jpg'
import eleven2019 from '../../images/2019/IMG-11.JPG'
import eleven2019Small from '../../images/2019/IMG-11-150.jpg'
import twelve2019 from '../../images/2019/IMG-12.JPG'
import twelve2019Small from '../../images/2019/IMG-12-150.jpg'
import thirteen2019 from '../../images/2019/IMG-13.JPG'
import thirteen2019Small from '../../images/2019/IMG-13-150.jpg'
import fourteen2019 from '../../images/2019/IMG-14.JPG'
import fourteen2019Small from '../../images/2019/IMG-14-150.jpg'
import fifteen2019 from '../../images/2019/IMG-15.JPG'
import fifteen2019Small from '../../images/2019/IMG-15-150.jpg'
import sixteen2019 from '../../images/2019/IMG-16.JPG'
import sixteen2019Small from '../../images/2019/IMG-16-150.jpg'
import seventeen2019 from '../../images/2019/IMG-17.JPG'
import seventeen2019Small from '../../images/2019/IMG-17-150.jpg'
import eighteen2019 from '../../images/2019/IMG-18.JPG'
import eighteen2019Small from '../../images/2019/IMG-18-150.jpg'
import nineteen2019 from '../../images/2019/IMG-19.JPG'
import nineteen2019Small from '../../images/2019/IMG-19-150.jpg'
import twenty2019 from '../../images/2019/IMG-20.JPG'
import twenty2019Small from '../../images/2019/IMG-20-150.jpg'
import twentyone2019 from '../../images/2019/IMG-21.JPG'
import twentyone2019Small from '../../images/2019/IMG-21-150.jpg'
import twentytwo2019 from '../../images/2019/IMG-22.JPG'
import twentytwo2019Small from '../../images/2019/IMG-22-150.jpg'
import twentythree2019 from '../../images/2019/IMG-23.JPG'
import twentythree2019Small from '../../images/2019/IMG-23-150.jpg'
import twentyfour2019 from '../../images/2019/IMG-24.JPG'
import twentyfour2019Small from '../../images/2019/IMG-24-150.jpg'
import twentyfive2019 from '../../images/2019/IMG-25.JPG'
import twentyfive2019Small from '../../images/2019/IMG-25-150.jpg'
import twentysix2019 from '../../images/2019/IMG-26.JPG'
import twentysix2019Small from '../../images/2019/IMG-26-150.jpg'
import twentyseven2019 from '../../images/2019/IMG-27.JPG'
import twentyseven2019Small from '../../images/2019/IMG-27-150.jpg'
import twentyeight2019 from '../../images/2019/IMG-28.JPG'
import twentyeight2019Small from '../../images/2019/IMG-28-150.jpg'
import twentynine2019 from '../../images/2019/IMG-29.JPG'
import twentynine2019Small from '../../images/2019/IMG-29-150.jpg'
import thirty2019 from '../../images/2019/IMG-30.JPG'
import thirty2019Small from '../../images/2019/IMG-30-150.jpg'
import thirtyone2019 from '../../images/2019/IMG-31.JPG'
import thirtyone2019Small from '../../images/2019/IMG-31-150.jpg'
import thirtytwo2019 from '../../images/2019/IMG-32.JPG'
import thirtytwo2019Small from '../../images/2019/IMG-32-150.jpg'
import thirtythree2019 from '../../images/2019/IMG-33.JPG'
import thirtythree2019Small from '../../images/2019/IMG-33-150.jpg'
import thirtyfour2019 from '../../images/2019/IMG-34.JPG'
import thirtyfour2019Small from '../../images/2019/IMG-34-150.jpg'
import thirtyfive2019 from '../../images/2019/IMG-35.JPG'
import thirtyfive2019Small from '../../images/2019/IMG-35-150.jpg'
import thirtysix2019 from '../../images/2019/IMG-36.JPG'
import thirtysix2019Small from '../../images/2019/IMG-36-150.jpg'
import thirtyseven2019 from '../../images/2019/IMG-37.JPG'
import thirtyseven2019Small from '../../images/2019/IMG-37-150.jpg'
import thirtyeight2019 from '../../images/2019/IMG-38.JPG'
import thirtyeight2019Small from '../../images/2019/IMG-38-150.jpg'
import thirtynine2019 from '../../images/2019/IMG-39.JPG'
import thirtynine2019Small from '../../images/2019/IMG-39-150.jpg'
import forty2019 from '../../images/2019/IMG-40.JPG'
import forty2019Small from '../../images/2019/IMG-40-150.jpg'
import fortyone2019 from '../../images/2019/IMG-41.JPG'
import fortyone2019Small from '../../images/2019/IMG-41-150.jpg'
import fortytwo2019 from '../../images/2019/IMG-42.JPG'
import fortytwo2019Small from '../../images/2019/IMG-42-150.jpg'
import fortythree2019 from '../../images/2019/IMG-43.JPG'
import fortythree2019Small from '../../images/2019/IMG-43-150.jpg'

import one2018 from '../../images/2018/IMG-1.JPG'
import one2018Small from '../../images/2018/IMG-1-150.jpg'
import two2018 from '../../images/2018/IMG-2.JPG'
import two2018Small from '../../images/2018/IMG-2-150.jpg'
import three2018 from '../../images/2018/IMG-3.JPG'
import three2018Small from '../../images/2018/IMG-3-150.jpg'
import four2018 from '../../images/2018/IMG-4.JPG'
import four2018Small from '../../images/2018/IMG-4-150.jpg'
import five2018 from '../../images/2018/IMG-5.JPG'
import five2018Small from '../../images/2018/IMG-5-150.jpg'
import six2018 from '../../images/2018/IMG-6.JPG'
import six2018Small from '../../images/2018/IMG-6-150.jpg'
import seven2018 from '../../images/2018/IMG-7.JPG'
import seven2018Small from '../../images/2018/IMG-7-150.jpg'
import eight2018 from '../../images/2018/IMG-8.JPG'
import eight2018Small from '../../images/2018/IMG-8-150.jpg'
import nine2018 from '../../images/2018/IMG-9.JPG'
import nine2018Small from '../../images/2018/IMG-9-150.jpg'
import ten2018 from '../../images/2018/IMG-10.JPG'
import ten2018Small from '../../images/2018/IMG-10-150.jpg'
import eleven2018 from '../../images/2018/IMG-11.JPG'
import eleven2018Small from '../../images/2018/IMG-11-150.jpg'
import twelve2018 from '../../images/2018/IMG-12.JPG'
import twelve2018Small from '../../images/2018/IMG-12-150.jpg'
import thirteen2018 from '../../images/2018/IMG-13.JPG'
import thirteen2018Small from '../../images/2018/IMG-13-150.jpg'
import fourteen2018 from '../../images/2018/IMG-14.JPG'
import fourteen2018Small from '../../images/2018/IMG-14-150.jpg'
import fifteen2018 from '../../images/2018/IMG-15.JPG'
import fifteen2018Small from '../../images/2018/IMG-15-150.jpg'
import sixteen2018 from '../../images/2018/IMG-16.JPG'
import sixteen2018Small from '../../images/2018/IMG-16-150.jpg'
import seventeen2018 from '../../images/2018/IMG-17.JPG'
import seventeen2018Small from '../../images/2018/IMG-17-150.jpg'
import eighteen2018 from '../../images/2018/IMG-18.JPG'
import eighteen2018Small from '../../images/2018/IMG-18-150.jpg'
import nineteen2018 from '../../images/2018/IMG-19.JPG'
import nineteen2018Small from '../../images/2018/IMG-19-150.jpg'

import one2017 from '../../images/2017/IMG-1.JPG'
import one2017Small from '../../images/2017/IMG-1-150.jpg'
import two2017 from '../../images/2017/IMG-2.JPG'
import two2017Small from '../../images/2017/IMG-2-150.jpg'
import three2017 from '../../images/2017/IMG-3.JPG'
import three2017Small from '../../images/2017/IMG-3-150.jpg'
import four2017 from '../../images/2017/IMG-4.JPG'
import four2017Small from '../../images/2017/IMG-4-150.jpg'
import five2017 from '../../images/2017/IMG-5.JPG'
import five2017Small from '../../images/2017/IMG-5-150.jpg'
import six2017 from '../../images/2017/IMG-6.JPG'
import six2017Small from '../../images/2017/IMG-6-150.jpg'
import seven2017 from '../../images/2017/IMG-7.JPG'
import seven2017Small from '../../images/2017/IMG-7-150.jpg'
import eight2017 from '../../images/2017/IMG-8.JPG'
import eight2017Small from '../../images/2017/IMG-8-150.jpg'
import nine2017 from '../../images/2017/IMG-9.JPG'
import nine2017Small from '../../images/2017/IMG-9-150.jpg'
import ten2017 from '../../images/2017/IMG-10.JPG'
import ten2017Small from '../../images/2017/IMG-10-150.jpg'
import eleven2017 from '../../images/2017/IMG-11.JPG'
import eleven2017Small from '../../images/2017/IMG-11-150.jpg'
import twelve2017 from '../../images/2017/IMG-12.JPG'
import twelve2017Small from '../../images/2017/IMG-12-150.jpg'
import thirteen2017 from '../../images/2017/IMG-13.JPG'
import thirteen2017Small from '../../images/2017/IMG-13-150.jpg'
import fourteen2017 from '../../images/2017/IMG-14.JPG'
import fourteen2017Small from '../../images/2017/IMG-14-150.jpg'
import fifteen2017 from '../../images/2017/IMG-15.JPG'
import fifteen2017Small from '../../images/2017/IMG-15-150.jpg'
import sixteen2017 from '../../images/2017/IMG-16.JPG'
import sixteen2017Small from '../../images/2017/IMG-16-150.jpg'
import seventeen2017 from '../../images/2017/IMG-17.JPG'
import seventeen2017Small from '../../images/2017/IMG-17-150.jpg'
import eighteen2017 from '../../images/2017/IMG-18.JPG'
import eighteen2017Small from '../../images/2017/IMG-18-150.jpg'
import nineteen2017 from '../../images/2017/IMG-19.JPG'
import nineteen2017Small from '../../images/2017/IMG-19-150.jpg'

import one2016 from '../../images/2016/IMG-1.JPG'
import one2016Small from '../../images/2016/IMG-1-150.jpg'
import two2016 from '../../images/2016/IMG-2.JPG'
import two2016Small from '../../images/2016/IMG-2-150.jpg'
import three2016 from '../../images/2016/IMG-3.JPG'
import three2016Small from '../../images/2016/IMG-3-150.jpg'
import four2016 from '../../images/2016/IMG-4.JPG'
import four2016Small from '../../images/2016/IMG-4-150.jpg'
import five2016 from '../../images/2016/IMG-5.JPG'
import five2016Small from '../../images/2016/IMG-5-150.jpg'
import six2016 from '../../images/2016/IMG-6.JPG'
import six2016Small from '../../images/2016/IMG-6-150.jpg'
import seven2016 from '../../images/2016/IMG-7.JPG'
import seven2016Small from '../../images/2016/IMG-7-150.jpg'
import eight2016 from '../../images/2016/IMG-8.JPG'
import eight2016Small from '../../images/2016/IMG-8-150.jpg'
import nine2016 from '../../images/2016/IMG-9.JPG'
import nine2016Small from '../../images/2016/IMG-9-150.jpg'
import ten2016 from '../../images/2016/IMG-10.JPG'
import ten2016Small from '../../images/2016/IMG-10-150.jpg'
import eleven2016 from '../../images/2016/IMG-11.JPG'
import eleven2016Small from '../../images/2016/IMG-11-150.jpg'
import twelve2016 from '../../images/2016/IMG-12.JPG'
import twelve2016Small from '../../images/2016/IMG-12-150.jpg'
import thirteen2016 from '../../images/2016/IMG-13.JPG'
import thirteen2016Small from '../../images/2016/IMG-13-150.jpg'
import fourteen2016 from '../../images/2016/IMG-14.JPG'
import fourteen2016Small from '../../images/2016/IMG-14-150.jpg'
import fifteen2016 from '../../images/2016/IMG-15.JPG'
import fifteen2016Small from '../../images/2016/IMG-1-150.jpg'

import one2015 from '../../images/2015/IMG-1.JPG'
import one2015Small from '../../images/2015/IMG-1-150.jpg'
import two2015 from '../../images/2015/IMG-2.JPG'
import two2015Small from '../../images/2015/IMG-2-150.jpg'
import three2015 from '../../images/2015/IMG-3.JPG'
import three2015Small from '../../images/2015/IMG-3-150.jpg'
import four2015 from '../../images/2015/IMG-4.JPG'
import four2015Small from '../../images/2015/IMG-4-150.jpg'
import five2015 from '../../images/2015/IMG-5.JPG'
import five2015Small from '../../images/2015/IMG-5-150.jpg'
import six2015 from '../../images/2015/IMG-6.JPG'
import six2015Small from '../../images/2015/IMG-6-150.jpg'
import seven2015 from '../../images/2015/IMG-7.JPG'
import seven2015Small from '../../images/2015/IMG-7-150.jpg'
import eight2015 from '../../images/2015/IMG-8.JPG'
import eight2015Small from '../../images/2015/IMG-8-150.jpg'

const images = {
  'danceevents': [{original: oneDance, thumbnail: oneDanceSmall}, {original: twoDance, thumbnail: twoDanceSmall}, 
    {original: threeDance, thumbnail: threeDanceSmall}, {original: fourDance, thumbnail: fourDanceSmall}, 
    {original: fiveDance, thumbnail: fiveDanceSmall}, {original: sixDance, thumbnail: sixDanceSmall}, 
    {original: sevenDance, thumbnail: sevenDanceSmall}, {original: eightDance, thumbnail: eightDanceSmall}, 
    {original: nineDance, thumbnail: nineDanceSmall}],
  '2023': [{original: one2023, thumbnail: one2023Small}, {original: two2023, thumbnail: two2023Small},
    {original: three2023, thumbnail: three2023Small}, {original: four2023, thumbnail: four2023Small},
    {original: five2023, thumbnail: five2023Small}, {original: six2023, thumbnail: six2023Small},
    {original: seven2023, thumbnail: seven2023Small}, {original: eight2023, thumbnail: eight2023Small},
    {original: nine2023, thumbnail: nine2023Small}, {original: ten2023, thumbnail: ten2023Small},
    {original: eleven2023, thumbnail: eleven2023Small}, {original: twelve2023, thumbnail: twelve2023Small},
    {original: thirteen2023, thumbnail: thirteen2023Small}, {original: fourteen2023, thumbnail: fourteen2023Small},
    {original: fifteen2023, thumbnail: fifteen2023Small}, {original: sixteen2023, thumbnail: sixteen2023Small},
    {original: seventeen2023, thumbnail: seventeen2023Small}, {original: eighteen2023, thumbnail: eighteen2023Small},
    {original: nineteen2023, thumbnail: nineteen2023Small}, {original: twenty2023, thumbnail: twenty2023Small},
    {original: twentyone2023, thumbnail: twentyone2023Small}, {original: twentytwo2023, thumbnail: twentytwo2023Small},
    {original: twentythree2023, thumbnail: twentythree2023Small}, {original: twentyfour2023, thumbnail: twentyfour2023Small},
    {original: twentyfive2023, thumbnail: twentyfive2023Small}, {original: twentysix2023, thumbnail: twentysix2023Small},
    {original: twentyseven2023, thumbnail: twentyseven2023Small}, {original: twentyeight2023, thumbnail: twentyeight2023Small},
    {original: twentynine2023, thumbnail: twentynine2023Small}, {original: thirty2023, thumbnail: thirty2023Small},
    {original: thirtyone2023, thumbnail: thirtyone2023Small}, {original: thirtytwo2023, thumbnail: thirtytwo2023Small},
    {original: thirtythree2023, thumbnail: thirtythree2023Small}, {original: thirtyfour2023, thumbnail: thirtyfour2023Small},
    {original: thirtyfive2023, thumbnail: thirtyfive2023Small}, {original: thirtysix2023, thumbnail: thirtysix2023Small},
    {original: thirtyseven2023, thumbnail: thirtyseven2023Small}],
  '2022': [{original: one2022, thumbnail: one2022Small}, {original: two2022, thumbnail: two2022Small},
    {original: three2022, thumbnail: three2022Small}, {original: four2022, thumbnail: four2022Small},
    {original: five2022, thumbnail: five2022Small}, {original: six2022, thumbnail: six2022Small},
    {original: seven2022, thumbnail: seven2022Small}, {original: eight2022, thumbnail: eight2022Small},
    {original: nine2022, thumbnail: nine2022Small}, {original: ten2022, thumbnail: ten2022Small}, 
    {original: eleven2022, thumbnail: eleven2022Small}, {original: twelve2022, thumbnail: twelve2022Small}, 
    {original: thirteen2022, thumbnail: thirteen2022Small}, {original: fourteen2022, thumbnail: fourteen2022Small}, 
    {original: fifteen2022, thumbnail: fifteen2022Small}, {original: sixteen2022, thumbnail: sixteen2022Small},
    {original: seventeen2022, thumbnail: seventeen2022Small}, {original: eighteen2022, thumbnail: eighteen2022Small},
    {original: nineteen2022, thumbnail: nineteen2022Small}, {original: twenty2022, thumbnail: twenty2022Small},
    {original: twentyone2022, thumbnail: twentyone2022Small}, {original: twentytwo2022, thumbnail: twentytwo2022Small},
    {original: twentythree2022, thumbnail: twentythree2022Small}, {original: twentyfour2022, thumbnail: twentyfour2022Small}],
  '2021': [{original: one2021, thumbnail: one2021Small}, {original: two2021, thumbnail: two2021Small},
    {original: three2021, thumbnail: three2021Small}, {original: four2021, thumbnail: four2021Small},
    {original: five2021, thumbnail: five2021Small}, {original: six2021, thumbnail: six2021Small},
    {original: seven2021, thumbnail: seven2021Small}, {original: eight2021, thumbnail: eight2021Small},
    {original: nine2021, thumbnail: nine2021Small}, {original: ten2021, thumbnail: ten2021Small}, 
    {original: eleven2021, thumbnail: eleven2021Small}, {original: twelve2021, thumbnail: twelve2021Small}, 
    {original: thirteen2021, thumbnail: thirteen2021Small}, {original: fourteen2021, thumbnail: fourteen2021Small}, 
    {original: fifteen2021, thumbnail: fifteen2021Small}, {original: sixteen2021, thumbnail: sixteen2021Small}],
  '2020': [{original: one2020, thumbnail: one2020Small}, {original: two2020, thumbnail: two2020Small},
    {original: three2020, thumbnail: three2020Small}, {original: four2020, thumbnail: four2020Small},
    {original: five2020, thumbnail: five2020Small}, {original: six2020, thumbnail: six2020Small},
    {original: seven2020, thumbnail: seven2020Small}, {original: eight2020, thumbnail: eight2020Small},
    {original: nine2020, thumbnail: nine2020Small}, {original: ten2020, thumbnail: ten2020Small}, 
    {original: eleven2020, thumbnail: eleven2020Small}, {original: twelve2020, thumbnail: twelve2020Small}, 
    {original: thirteen2020, thumbnail: thirteen2020Small}, {original: fourteen2020, thumbnail: fourteen2020Small}, 
    {original: fifteen2020, thumbnail: fifteen2020Small}, {original: sixteen2020, thumbnail: sixteen2020Small}, 
    {original: seventeen2020, thumbnail: seventeen2020Small}, {original: eighteen2020, thumbnail: eighteen2020Small}, 
    {original: nineteen2020, thumbnail: nineteen2020Small}, {original: twenty2020, thumbnail: twenty2020Small}],
  '2019': [{original: one2019, thumbnail: one2019Small}, {original: two2019, thumbnail: two2019Small}, 
    {original: three2019, thumbnail: three2019Small}, {original: four2019, thumbnail: four2019Small}, 
    {original: five2019, thumbnail: five2019Small}, {original: six2019, thumbnail: six2019Small}, 
    {original: seven2019, thumbnail: seven2019Small}, {original: eight2019, thumbnail: eight2019Small}, 
    {original: nine2019, thumbnail: nine2019Small}, {original: ten2019, thumbnail: ten2019Small}, 
    {original: eleven2019, thumbnail: eleven2019Small}, {original: twelve2019, thumbnail: twelve2019Small}, 
    {original: thirteen2019, thumbnail: thirteen2019Small}, {original: fourteen2019, thumbnail: fourteen2019Small}, 
    {original: fifteen2019, thumbnail: fifteen2019Small}, {original: sixteen2019, thumbnail: sixteen2019Small},
    {original: seventeen2019, thumbnail: seventeen2019Small}, {original: eighteen2019, thumbnail: eighteen2019Small}, 
    {original: nineteen2019, thumbnail: nineteen2019Small}, {original: twenty2019, thumbnail: twenty2019Small}, 
    {original: twentyone2019, thumbnail: twentyone2019Small}, {original: twentytwo2019, thumbnail: twentytwo2019Small}, 
    {original: twentythree2019, thumbnail: twentythree2019Small}, {original: twentyfour2019, thumbnail: twentyfour2019Small}, 
    {original: twentyfive2019, thumbnail: twentyfive2019Small}, {original: twentysix2019, thumbnail: twentysix2019Small}, 
    {original: twentyseven2019, thumbnail: twentyseven2019Small}, {original: twentyeight2019, thumbnail: twentyeight2019Small}, 
    {original: twentynine2019, thumbnail: twentynine2019Small}, {original: thirty2019, thumbnail: thirty2019Small}, 
    {original: thirtyone2019, thumbnail: thirtyone2019Small}, {original: thirtytwo2019, thumbnail: thirtytwo2019Small}, 
    {original: thirtythree2019, thumbnail: thirtythree2019Small}, {original: thirtyfour2019, thumbnail: thirtyfour2019Small}, 
    {original: thirtyfive2019, thumbnail: thirtyfive2019Small}, {original: thirtysix2019, thumbnail: thirtysix2019Small}, 
    {original: thirtyseven2019, thumbnail: thirtyseven2019Small}, {original: thirtyeight2019, thumbnail: thirtyeight2019Small}, 
    {original: thirtynine2019, thumbnail: thirtynine2019Small}, {original: forty2019, thumbnail: forty2019Small}, 
    {original: fortyone2019, thumbnail: fortyone2019Small}, {original: fortytwo2019, thumbnail: fortytwo2019Small}, 
    {original: fortythree2019, thumbnail: fortythree2019Small}],
  '2018': [{original: one2018, thumbnail: one2018Small}, {original: two2018, thumbnail: two2018Small}, 
    {original: three2018, thumbnail: three2018Small}, {original: four2018, thumbnail: four2018Small}, 
    {original: five2018, thumbnail: five2018Small}, {original: six2018, thumbnail: six2018Small}, 
    {original: seven2018, thumbnail: seven2018Small}, {original: eight2018, thumbnail: eight2018Small}, 
    {original: nine2018, thumbnail: nine2018Small}, {original: ten2018, thumbnail: ten2018Small}, 
    {original: eleven2018, thumbnail: eleven2018Small}, {original: twelve2018, thumbnail: twelve2018Small}, 
    {original: thirteen2018, thumbnail: thirteen2018Small}, {original: fourteen2018, thumbnail: fourteen2018Small}, 
    {original: fifteen2018, thumbnail: fifteen2018Small}, {original: sixteen2018, thumbnail: sixteen2018Small},
    {original: seventeen2018, thumbnail: seventeen2018Small}, {original: eighteen2018, thumbnail: eighteen2018Small}, 
    {original: nineteen2018, thumbnail: nineteen2018Small}],
  '2017': [{original: one2017, thumbnail: one2017Small}, {original: two2017, thumbnail: two2017Small}, 
    {original: three2017, thumbnail: three2017Small}, {original: four2017, thumbnail: four2017Small}, 
    {original: five2017, thumbnail: five2017Small}, {original: six2017, thumbnail: six2017Small}, 
    {original: seven2017, thumbnail: seven2017Small}, {original: eight2017, thumbnail: eight2017Small}, 
    {original: nine2017, thumbnail: nine2017Small}, {original: ten2017, thumbnail: ten2017Small}, 
    {original: eleven2017, thumbnail: eleven2017Small}, {original: twelve2017, thumbnail: twelve2017Small}, 
    {original: thirteen2017, thumbnail: thirteen2017Small}, {original: fourteen2017, thumbnail: fourteen2017Small}, 
    {original: fifteen2017, thumbnail: fifteen2017Small}, {original: sixteen2017, thumbnail: sixteen2017Small},
    {original: seventeen2017, thumbnail: seventeen2017Small}, {original: eighteen2017, thumbnail: eighteen2017Small}, 
    {original: nineteen2017, thumbnail: nineteen2017Small}],
  '2016': [{original: one2016, thumbnail: one2016Small}, {original: two2016, thumbnail: two2016Small}, 
    {original: three2016, thumbnail: three2016Small}, {original: four2016, thumbnail: four2016Small}, 
    {original: five2016, thumbnail: five2016Small}, {original: six2016, thumbnail: six2016Small}, 
    {original: seven2016, thumbnail: seven2016Small}, {original: eight2016, thumbnail: eight2016Small}, 
    {original: nine2016, thumbnail: nine2016Small}, {original: ten2016, thumbnail: ten2016Small}, 
    {original: eleven2016, thumbnail: eleven2016Small}, {original: twelve2016, thumbnail: twelve2016Small}, 
    {original: thirteen2016, thumbnail: thirteen2016Small}, 
    {original: fourteen2016, thumbnail: fourteen2016Small}, 
    {original: fifteen2016, thumbnail: fifteen2016Small}],
  '2015': [{original: one2015, thumbnail: one2015Small}, {original: two2015, thumbnail: two2015Small}, 
    {original: three2015, thumbnail: three2015Small}, {original: four2015, thumbnail: four2015Small}, 
    {original: five2015, thumbnail: five2015Small}, {original: six2015, thumbnail: six2015Small}, 
    {original: seven2015, thumbnail: seven2015Small}, {original: eight2015, thumbnail: eight2015Small}]
}

export default images