import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='copyright'>&copy; 2019 CC Choreography. <span>WEBSITE BY LAURA ASHLEIGH</span></div>
    </div>

  )
}

export default Footer