import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './Icons.css'

const icons = (props) => {
  return (
    <div className={`${!(props.email || props.instagramAdults) ? 'two-icons' : 'three-icons'} social-icons-wrapper`}>
      {!props.instagram ? null :
        <div className='icon-and-text'>
          <a href='https://www.instagram.com/cc.choreography/?hl=en' target='__blank'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          {!(props.instagram && props.instagramAdults) ? null :
            <div className='icon-text'>(Kids)</div>
          }
        </div>
      }
      {
        !props.instagramAdults ? null :
        <div className='icon-and-text'>
          <a href='https://www.instagram.com/cc.choreography.adults/?hl=en' target='__blank'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <div className='icon-text'>(Adults)</div>
        </div>
      }
      {!props.facebook ? null :
        <a href='https://www.facebook.com/ccchoreographydance' target='__blank'>
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      }
      {!props.email ? null :
        <a href='mailto:chelsea@ccchoreography.org'>
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      }
    </div>
  )
}

export default icons