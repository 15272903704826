import React from 'react'
import { ageGroups, timetable } from './TimetableConfig'
import PageWrapper from '../PageWrapper/PageWrapper'
import Timetable from './Timetable.pdf'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './TimetablePage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const TimetablePage = () => {
  const days = Object.keys(timetable[0])

  return (
    <PageWrapper title='2024 Timetable'>
      <a href={Timetable} download className='download-timetable'>
        <div>Download PDF</div>
        <FontAwesomeIcon icon={faDownload} />
      </a>
      
      <PerfectScrollbar>
        <table className='timetable'>
          <thead>
            <tr>
              {days.map(day =>
                <th key={day}>{day}</th>
              )}
            </tr>
          </thead>

          {timetable.map((row, key) =>
            <tbody key={key}>
              <tr>
                {days.map(day =>
                  <td key={day}>
                    <div>
                      {row[day]['Class']}
                    </div>
                    <div>
                      {row[day]['Time']}
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
            )}
        </table>
      </PerfectScrollbar>

      <div className='sub-timetable-info-wrapper'>
        <div className='red-text'>Age Groups:</div>
        <div className='sub-timetable-info'>
          {ageGroups.map(group => 
            <div key={group}>{group}</div>
          )}
        </div>
      </div>

      {/* <div className='sub-timetable-info-wrapper'>
        <div className='red-text'>Term 1 Starts:</div>
        <div className='sub-timetable-info'>
          Friday 29th January - Thursday 1st April (9 week term)
        </div>
      </div> */}
    </PageWrapper>
  )
}

export default TimetablePage